<template>
  <div class="container pt-2 px-0 px-sm-2 px-md-5 px-lg-5" v-if="pedidoFE.objPedido">

    <div class="row px-0 px-sm-2 px-md-5 px-lg-5">
      <div class="col text-center mt-3 mt-md-4 mt-lg-5 pt-1 pt-md-5 pt-lg-5">
        <h3 :class="issuserParam.fuenteTitulo && issuserParam.fuenteTitulo != '-1'? 'title-client' : ''">Pedido Nro. {{ pedidoFE.idPedido }} Generado Correctamente</h3>
      </div>
      <div class="card p-0">
        <div class="card-body pt-4 pb-0">
          <div class="row pb-0 mt-0">
            <div class="col-12 col-md-6">
              <h5>Información Cliente:</h5>
              <p>Nombre: {{ pedidoFE.objPedido.nombrePedido }}</p>
              <p>Documento: {{ pedidoFE.objPedido.documentoReceptor}}</p>
              <p>Dirección: {{ pedidoFE.objPedido.direccionPedido }}</p>
              <p>Ciudad: {{ pedidoFE.objPedido.ciudadPedido }}</p>
              <p>Departamento: {{ pedidoFE.objPedido.departamentoPedido }}</p>
              <p>Email: {{ pedidoFE.objPedido.emailPedido }}</p>
              <p v-if="pedidoFE.objPedido.comentarioPedido">Comentario: {{ pedidoFE.objPedido.comentarioPedido }}</p>
            </div>
            <div class="col-12 col-md-6">
              <h5>Información del pedido:</h5>
              <p>Nro Pedido: {{ pedidoFE.idPedido }}</p>
              <p>Fecha: {{ pedidoFE.fecha }}</p>
              <p>Hora: {{ pedidoFE.hora }}</p>
              <p v-if="pedidoFE.objPedido.carritoDD[1]">Condición de pago: {{ pedidoFE.objPedido.carritoDD[1].variableValor }}</p>
              <p v-if="pedidoFE.objPedido.carritoDD[2]">Titular de la cuenta: {{ pedidoFE.objPedido.carritoDD[2].variableValor }}</p>
            </div>
          </div>
        </div>
        <div class="card-body pb-0">
          <ul class="list-group list-group-flush pt-0 mt-0 border-top">
            <li class="list-group-item">
              <div class="row">
                <div class="col-6 fw-bold">Productos</div>
                <div class="col-3 fw-bold text-end">Cantidad</div>
                <div class="col-3 text-align-end fw-bold text-end">SubTotal</div>
              </div>
            </li>
            <li class="list-group-item py-1" v-for="(article, index) in  colCarrito" :key="index">
              <div class="row">
                <div class="col-6">{{ article.nombreProducto }}</div>
                <div class="col-3  text-end">{{ article.cantidad }}</div>
                <div class="col-3 text-align-end fw-bold text-end">{{ formatMoney(issuserParam.monedalp) + " "
                  + parseFloat(article.subTotal).toFixed(decimal) }}</div>
              </div>
            </li>
          </ul>
        </div>
        <div class="card-footer text-center text-md-left text-lg-left pb-0 mb-0">
          <div class="row px-3" id="trans-amount">
            <div class="col-7 text-start">
              <h5>Total:</h5>
            </div>
            <div class="col-5 text-end">
              <h5>{{ formatMoney(issuserParam.monedalp) }} {{ total }}</h5>
            </div>
          </div>
          <input type="hidden" id="totalPedido" value="<%=total%>" />
        </div>

      </div>
    </div>
    <div class="row pb-md-3 pb-lg-3 pb-5 mt-5 mb-5 mb-sm-5 mb-md-5">
      <div class="col pb-5 pb-md-5 pb-lg-5 align-items-center justify-content-center content-align-center text-center">
        <button class="btn btn-client" @click="router.push({ name: 'Home' })">
          <fa icon="cart-arrow-down" /> Continuar Comprando
        </button>
      </div>
    </div>
</div>
</template>

<script>
import { formatMoney, tipoComprobantes, borrarCarrito } from "@/composable/functions";
import { inject, watch, ref } from "vue";
import router from "@/router";
export default {
  setup() {
    const cart = inject("cart");
    const decimal = inject("decimal");
    const issuserParam = inject("issuserParam");
    const pedidoFE = inject("pedidoFE");
    const colCarrito = ref([]);
    let total = 0;

    const instanceFE = () => {
      borrarCarrito();
      cart.value = [];
      colCarrito.value = pedidoFE.value.objPedido.listaCarritoCompras;
      total = parseFloat(colCarrito.value.reduce((acc, curr) => {
        return acc + parseFloat(curr.subTotal);
      }, 0)).toFixed(decimal);
    };

    if (pedidoFE.value.cod_respuesta) {
      instanceFE();
    }
    watch(
      () => pedidoFE.value,
      (newParams) => {
        if (!newParams) return;
        instanceFE();
      }
    );

    return { router, pedidoFE, tipoComprobantes, issuserParam, decimal, formatMoney, colCarrito, total, cart };
  },
};
</script>
